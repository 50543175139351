<template>
  <div>
    <div class="noticeBox">
      <van-row>
        <van-col span="24">
          <div class="process-row">
            <div class="process-box">
              <div class="box-title">月度</div>
              <div class="box-process">
                <van-circle
                  :value="cityData.stationPct<0?100:cityData.stationPct"
                  :speed="100"
                  :color="cityData.stationPct >= 0 || !cityData.stationPct ? '#839BFB' : 'red'"
                  :text="cityData.stationPct ? cityData.stationPct + '%' : 0 + '%'"
                  layer-color="#e2e2e2"
                  size="1rem"
                />
                <div class="box-data">
                  <div class="data-item">基数：{{ cityData.monthBase }}</div>
                  <div class="data-item">目标：{{ cityData.monthTarget }}</div>
                </div>
              </div>

            </div>
            <div class="process-box">
              <div class="box-title">季度</div>
              <div class="box-process">
                <van-circle
                  :value="cityData.quarterStationPct<0?100:cityData.quarterStationPct"
                  :speed="100"
                  :color="cityData.quarterStationPct >= 0 || !cityData.quarterStationPct ? '#839BFB' : 'red'"
                  :text="cityData.quarterStationPct ? cityData.quarterStationPct + '%' : 0 + '%' "
                  layer-color="#e2e2e2"
                  size="1rem"
                />
                <div class="box-data">
                  <div class="data-item">基数：{{ cityData.quarterBase }}</div>
                  <div class="data-item">目标：{{ cityData.quarterTarget }}</div>
                </div>
              </div>

            </div>
          </div>
          <!--新建站点-->
          <div class="static-row" v-if="tabType === 'NewlyStation'">
            <div class="static-item">
              <div class="static-title">已加盟</div>
              <div class="static-data">
                <div class="static-data-item">
                  新增：{{ cityData.newlyJoinedStation }}
                </div>
                <div class="static-data-item">
                  累计：{{ cityData.totalJoinedStation }}
                </div>
              </div>
            </div>
            <div class="static-item">
              <div class="static-title">已签约</div>
              <div class="static-data">
                <div class="static-data-item">
                  新增：{{ cityData.newlySignedStation }}
                </div>
                <div class="static-data-item">
                  累计：{{ cityData.totalSignedStation }}
                </div>
              </div>
            </div>
            <div class="static-item">
              <div class="static-title">新建站点</div>
              <div class="static-data">
                <div class="static-data-item">
                  新增：{{ cityData.quarterNewlyStation }}
                </div>
                <div class="static-data-item">
                  累计：{{ cityData.totalNewlyStation }}
                </div>
              </div>
            </div>
          </div>
          <!--有效-->
          <div
            class="flex justify_content_space_around"
            v-if="tabType === 'ActiveStation'"
          >
            <div class="flex align_items_center">
              <div class="font-15">新增有效：</div>
              <div class="font-15">{{ cityData.newlyActiveStation }}</div>
            </div>
            <div class="flex align_items_center">
              <div class="font-15">累计有效：</div>
              <div class="font-15">{{ cityData.totalActiveStation }}</div>
            </div>
          </div>
          <!--双百站点-->
          <div
            class="flex justify_content_space_around"
            v-if="tabType === 'DoubleHundred'"
          >
            <div class="flex align_items_center">
              <div class="font-15">新增双百：</div>
              <div class="font-15">
                {{ cityData.newlyDoubleHundredStation }}
              </div>
            </div>
            <div class="flex align_items_center">
              <div class="font-15">累计双百：</div>
              <div class="font-15">
                {{ cityData.totalDoubleHundredStation }}
              </div>
            </div>
          </div>
          <!--时点余额-->
          <div
            class="flex justify_content_space_around"
            v-if="tabType === 'PointBalance'"
          >
            <div class="flex align_items_center">
              <div class="font-15">新增余额：</div>
              <div class="font-15">{{ cityData.newlyPointBalance }}</div>
            </div>
            <div class="flex align_items_center">
              <div class="font-15">累计余额：</div>
              <div class="font-15">{{ cityData.totalPointBalance }}</div>
            </div>
          </div>
          <!--上线-->
          <div
                  class="flex justify_content_space_around"
                  v-if="tabType === 'onlineStation'"
          >
            <div class="flex align_items_center">
              <div class="font-15">新增上线：</div>
              <div class="font-15">{{ cityData.newlyOnlineCount }}</div>
            </div>
            <div class="flex align_items_center">
              <div class="font-15">累计上线：</div>
              <div class="font-15">{{ cityData.onlineCount }}</div>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="sub-goals">
      <div class="plate" v-if="dataList.id">
        <div class="content-title">
          <div class="header-text">{{ dataList.objName }}</div>
          <van-image
            v-if="dataList.stationRanking < 4"
            width="30"
            height="30"
            :src="
              dataList.stationRanking === 1
                ? gold
                : dataList.stationRanking === 2
                ? silver
                : dataList.stationRanking === 3
                ? copper
                : ''
            "
            round
          />

          <div
            v-if="dataList.stationRanking > 3"
            :style="bgBox"
            class="background-image"
          >
            {{ dataList.stationRanking }}
          </div>
        </div>
        <van-row>
          <van-col span="24">
            <div class="process-row">
              <div class="process-box">
                <div class="box-title">月度</div>
                <div class="box-process">
                  <van-circle
                    :value="dataList.stationPct<0?100:dataList.stationPct"
                    :speed="100"
                    :color="dataList.stationPct >= 0 || !dataList.stationPct ? '#839BFB' : 'red'"
                    :text="dataList.stationPct ? dataList.stationPct + '%' : 0 + '%'"
                    layer-color="#e2e2e2"
                    size="1rem"
                  />
                  <div class="box-data">
                    <div class="data-item">基数：{{ dataList.monthBase }}</div>
                    <div class="data-item">目标：{{ dataList.monthTarget }}</div>
                  </div>
                </div>
              </div>
              <div class="process-box">
                <div class="box-title">季度</div>
                <div class="box-process">
                  <van-circle
                    :value="dataList.quarterStationPct<0?100:dataList.quarterStationPct"
                    :speed="100"
                    :color="dataList.quarterStationPct >= 0 || !dataList.quarterStationPct ? '#839BFB' : 'red'"
                    :text="dataList.quarterStationPct? dataList.quarterStationPct + '%' : 0 + '%'"
                    layer-color="#e2e2e2"
                    size="1rem"
                  />
                  <div class="box-data">
                    <div class="data-item">基数：{{ dataList.quarterBase }}</div>
                    <div class="data-item">目标：{{ dataList.quarterTarget }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!--新建站点-->
            <div class="static-row" v-if="tabType === 'NewlyStation'">
              <div class="static-item">
                <div class="static-title">已加盟</div>
                <div class="static-data">
                  <div class="static-data-item">
                    新增：{{ dataList.newlyJoinedStation}}
                  </div>
                  <div class="static-data-item">
                    累计：{{ dataList.totalJoinedStation}}
                  </div>
                </div>
              </div>
              <div class="static-item">
                <div class="static-title">已签约</div>
                <div class="static-data">
                  <div class="static-data-item">
                    新增：{{ dataList.newlySignedStation }}
                  </div>
                  <div class="static-data-item">
                    累计：{{ dataList.totalSignedStation }}
                  </div>
                </div>
              </div>
              <div class="static-item">
                <div class="static-title">新建站点</div>
                <div class="static-data">
                  <div class="static-data-item">
                    新增：{{ dataList.quarterNewlyStation }}
                  </div>
                  <div class="static-data-item">
                    累计：{{ dataList.totalNewlyStation }}
                  </div>
                </div>
              </div>
            </div>
            <!--有效-->
            <div
              class="flex justify_content_space_around"
              v-if="tabType === 'ActiveStation'"
            >
              <div class="flex align_items_center">
                <div class="font-15">新增有效：</div>
                <div class="font-15">{{ dataList.newlyActiveStation }}</div>
              </div>
              <div class="flex align_items_center">
                <div class="font-15">累计有效：</div>
                <div class="font-15">{{ dataList.totalActiveStation }}</div>
              </div>
            </div>
            <!--双百站点-->
            <div
              class="flex justify_content_space_around"
              v-if="tabType === 'DoubleHundred'"
            >
              <div class="flex align_items_center">
                <div class="font-15">新增双百：</div>
                <div class="font-15">
                  {{ dataList.newlyDoubleHundredStation }}
                </div>
              </div>
              <div class="flex align_items_center">
                <div class="font-15">累计双百：</div>
                <div class="font-15">
                  {{ dataList.totalDoubleHundredStation }}
                </div>
              </div>
            </div>
            <!--时点余额-->
            <div
              class="flex justify_content_space_around"
              v-if="tabType === 'PointBalance'"
            >
              <div class="flex align_items_center">
                <div class="font-15">新增余额：</div>
                <div class="font-15">{{ dataList.newlyPointBalance }}</div>
              </div>
              <div class="flex align_items_center">
                <div class="font-15">累计余额：</div>
                <div class="font-15">{{ dataList.totalPointBalance }}</div>
              </div>
            </div>
            <!--上线-->
            <div
                    class="flex justify_content_space_around"
                    v-if="tabType === 'onlineStation'"
            >
              <div class="flex align_items_center">
                <div class="font-15">新增上线：</div>
                <div class="font-15">{{ dataList.newlyOnlineCount }}</div>
              </div>
              <div class="flex align_items_center">
                <div class="font-15">累计上线：</div>
                <div class="font-15">{{ dataList.onlineCount }}</div>
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "operation",
  props: {
    cityData: Object,
    dataList: Object,
    tabType: String,
  },
  data() {
    return {
      bgBox: {
        backgroundImage: "url(" + require("../assets/img/ps/rank-bg.png") + ")",
      },
      gold: require("../assets/img/ps/gold.png"),
      silver: require("../assets/img/ps/silver.png"),
      copper: require("../assets/img/ps/copper.png"),
    };
  },
  methods: {},
};
</script>

<style lang="less">
    @import "../style/goals.less";
    .noticeBox {
        background: #ffffff;
        margin-bottom: 0.1rem;
    }

</style>

import axios from './request'
import {businessApi, sign} from "./api";

//POST
// 获取部门树
export var geUserDeptTree=(params)=>{
    return axios({
        url:`${businessApi}/performanceRanking/geUserDeptTree`,
        method:'post',
        data:sign(params)
    })
};
//获取用户类型 POST
export var geUserInfo = (params)=>{
    return axios({
        url:`${businessApi}/performanceRanking/geUserInfo`,
        method: 'post',
        data:sign(params)
    })
};
// 业绩目标与达成查询
export var getPerformanceAchievement = (params)=>{
    return axios({
        url:`${businessApi}/performanceRanking/getPerformanceAchievement`,
        method: 'post',
        data:sign(params)
    })
};
// 普通和高级用户查询人员地区排名
export var getPerformanceRanking = (params)=>{
    return axios({
        url:`${businessApi}/performanceRanking/getPerformanceRanking`,
        method: 'post',
        data:sign(params)
    })
};
// 运营人员查询排名
export var getOperatorsRanking = (params)=>{
    return axios({
        url:`${businessApi}/performanceRanking/getOperatorsRanking`,
        method: 'post',
        data:sign(params)
    })
};

<template>
    <div class="ps-goals">
        <div class="one-title-drop">
            <div>
                <van-dropdown-menu active-color="#3F7C53" class="width-130">
                    <van-dropdown-item
                            v-model="targetDate"
                            :options="dateList"
                            @change="dateChange"
                    />
                </van-dropdown-menu>
            </div>
            <div>
                <van-dropdown-menu active-color="#3F7C53" class="width-130">
                    <van-dropdown-item v-model="deptNo" :options="dropList" @change="changeDept"/>
                </van-dropdown-menu>
            </div>
        </div>
        <van-tabs v-model="active" color="#3f7c53" @change="tabChange">
            <van-tab v-for="item in tabList"
                     :title="item.title"
                     :key="item.value">
                    <operation :cityData="cityData" :dataList="dataList" :tabType="stationType"  v-if="!loadingCityData"> </operation>
            </van-tab>
        </van-tabs>
        <div class="botton-box">
          <van-button class="publicBtnActive" @click="jumpTo">运营部及成员排名</van-button>
        </div>

    </div>

</template>

<script>
    import operation from "@/components/operation"
    import {getOperatorsRanking} from '@/utils/goals';
    import common from '@/utils/common'
    import moment from "moment";
    export default {
        name: "operationGoals",
        components: {
            operation
        },
        data(){
            return{
                active: 0, //默认新建站点
                dropList:common.getItem('cityList')?common.getItem('cityList'):
                    common.getItem('proviceArr')?common.getItem('proviceArr'):[],
                deptNo:common.getItem('cityList')?common.getItem('cityList')[0].value:
                    common.getItem('proviceArr')?common.getItem('proviceArr')[0].value:'', //运营部的code
                year: new Date().getFullYear(), //获取当前年
                month: new Date().getMonth() + 1, //获取当前月
                tabList:[
                    {title:'新建站点',value:'NewlyStation'},
                    {title:'有效站点',value:'ActiveStation'},
                    {title:'双百站点',value:'DoubleHundred'},
                    {title:'时点余额',value:'PointBalance'},
                   // { title: '达标站点', value: 'ReachToQs' },
                    {title:'上线站点',value:'onlineStation'}
                ],
                tabEnume: { //tab枚举
                    0: 'NewlyStation',
                    1: 'ActiveStation',
                    2: 'DoubleHundred',
                    3: 'PointBalance',
                    4: 'onlineStation'
                },
                cityData:{},
                dataList:{},
                stationType:'NewlyStation',
                loadingCityData: false,
                dateList:[
                    {text:moment().subtract(3,'months').format('YYYY/MM'),
                        value:moment().subtract(3,'months').format('YYYY/MM')},
                    {text:moment().subtract(2,'months').format('YYYY/MM'),
                        value:moment().subtract(2,'months').format('YYYY/MM')},
                    {text:moment().subtract(1,'months').format('YYYY/MM'),
                        value:moment().subtract(1,'months').format('YYYY/MM')},
                    {text:moment().format('YYYY/MM'),value: moment().format('YYYY/MM')},
                ],
                //每月10号前显示上月,否则显示本月
                targetDate:moment().format("YYYY-MM-DD")>moment().date(10).format('YYYY-MM-DD') ?
                    moment().format('YYYY/MM'):
                    moment().subtract(1,'months').format('YYYY/MM'),
            }
        },
        created() {
            this.seachData('AREA',this.deptNo);
            this.seachData('PERSON',common.getItem('uniqueNo') ? common.getItem('uniqueNo') : this.$route.query.uniqueNo);
        },
        methods:{
            dateChange(){
                this.dataList = {};
                this.cityData = {};
                this.seachData('AREA',this.deptNo);
                this.seachData('PERSON',common.getItem('uniqueNo') ? common.getItem('uniqueNo') : this.$route.query.uniqueNo);
            },
            //下拉框
            changeDept(){
                this.dataList = {};
                this.cityData = {};
                this.seachData('AREA',this.deptNo);
                this.seachData('PERSON',common.getItem('uniqueNo') ? common.getItem('uniqueNo') : this.$route.query.uniqueNo);
            },
            //切换tab类型
            tabChange(value){
                this.stationType = this.tabEnume[value];
                this.dataList = {};
                this.cityData = {};
                this.seachData('AREA',this.deptNo);
                this.seachData('PERSON',common.getItem('uniqueNo') ? common.getItem('uniqueNo') : this.$route.query.uniqueNo);
            },
            //查询数据更新3
            seachData(rankType,code){
                let data = {
                    targetDate:this.targetDate,//this.year+'/'+this.month,
                    rankingLevel: 'PROVINCE',
                    rankingType:rankType,//地区排名：AREA；人员排名：PERSON
                    objNo:code,
                    deptNo:this.deptNo,
                    stationType:this.stationType,
                }
                this.loadingCityData = true
                getOperatorsRanking(data).then(res=>{
                    this.loadingCityData = false
                    if(res.code===200 && res.data.businessCode ===200){
                        if(rankType === 'AREA'){
                            this.cityData = res.data.rankList;
                        }
                        if(rankType === 'PERSON'){
                            this.dataList = res.data.rankList;
                        }
                    }
                })
            },
            //跳转到运营部及人员排名
            jumpTo(){
                this.$router.push({
                    path:'/ps/ranking',
                    query:{
                        deptNo: this.deptNo,
                        targetDate: this.targetDate
                    }
                })

            }
        }
    }
</script>

<style lang="less">
    @import "../../style/goals";

</style>
